<template>
    <div class="quotes">
        <g-flex class="active-ticker" align="center" justify="between">
            <div class="ticker-symbol">
                <g-symbol-currency v-bind:name="ticker.base.toLowerCase()"/>
                <div class="name">{{ ticker.asset.name }}</div>
                <div class="symbol">
                    <span class="base-currency">{{ ticker.base }}</span><span class="counter-currency">:{{ ticker.counter }}</span>
                </div>
            </div>

            <div class="price" v-bind:class="increased">
                <div class="percent">{{ change24hPercent.toFixed(2) }}</div>
                <div class="value" v-bind:class="priceScale">{{ marketPrice.toString() }}</div>
            </div>

            <g-flex class="specifics" justify="between" align="top" gap="12">
                <div>
                    <div class="title">24h Vol.</div>
                    {{ ticker.volume_24 }}
                </div>
                <div>
                    <div class="title">24h High</div>
                    {{ ticker.high }}
                </div>
                <div>
                    <div class="title">24h Low</div>
                    {{ ticker.low }}
                </div>
                <div>
                    <div class="title">24h Change</div>
                    {{ Math.abs(change24h) >= 0.000001 ? change24h.toFixed(2) : '> 0.000001' }}
                </div>
            </g-flex>
        </g-flex>
    </div>

</template>

<script>

import { STATE } from '@/Ship';
import { ref, computed } from 'vue';
import { useQuoteActions, useQuoteComponents } from '@quotes';

const {
    getCryptoResource,
    getStockResource,
    getCommodityResource,
    getForexResource,
} = useQuoteActions();


export default {
    name: 'View',
    components: {
        ...useQuoteComponents()
    },
    setup() {

        const previousPrice= ref(0);
        const increased = ref('increase');

        const resources = {
            crypto: getCryptoResource,
            stocks: getStockResource,
            commodities: getCommodityResource,
            forex: getForexResource,
        };

        const ticker = computed(() => {
            const resource = resources[STATE.MARKET]();
            return resource.state[resource.index] || resource.model;
        });

        const priceScale = ref(false);
        const marketPrice = computed(() => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            priceScale.value = (marketPrice.value || 0) > previousPrice.value
                ? 'scale-increase'
                : 'scale-decrease';

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            previousPrice.value = marketPrice.value;

            return ticker.value.price;
        });

        const change24h = computed(() => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            increased.value = marketPrice.value > ticker.value.open
                ? 'increase'
                : 'decrease';
            return marketPrice.value - ticker.value.open;
        });

        const change24hPercent = computed(() => {
            return (marketPrice.value - ticker.value.open) * 100 / ticker.value.open;
        });

        return {
            ticker,
            increased,
            priceScale,
            marketPrice,
            change24h,
            change24hPercent,
        };
    }
};
</script>

<style lang="scss" scoped>
.quotes {
    z-index: 3;
    width: 100%;
    position: relative;
    container-type: inline-size;
}

.active-ticker {
    width: 100%;
    line-height: 1.1;
    //background-color: var(--bar-bg, $bar-bg);
    //clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 100%, 0 100%);
}

.ticker-symbol {
    padding: 8px;
    column-gap: 6px;
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    justify-content: center;
    flex-direction: column;

    & > .symbol {
        & > .base-currency {
            font-weight: 600;
            color: var(--title-text-color, $title-text-color);
        }

        & > .g-symbol {
            margin-left: 5px;
            vertical-align: middle;
            transform: rotate(90deg);
            fill: var(--title-text-color, $title-text-color);
        }
    }
}

.price {
    margin-left: 40px;
    font-weight: 600;
    text-align: right;

    & > .percent {
        font-weight: 400;
        color: var(--main-text-color, $main-text-color);
    }

    &.decrease > .percent::before {
        transform: rotate(45deg);
    }

    &.increase > .percent::before {
        transform: rotate(-45deg);
    }
}

.percent::after {
    content: '%';
}

.percent::before {
    content: '⮕';
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    display: inline-block;
    transition: transform 0.2s;
    font-weight: bold;
    margin-right: 5px;
}

.increase {
    color: var(--increase, $increase);
}

.decrease {
    color: var(--decrease, $decrease);
}

.specifics {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;

    & > :first-child {
        text-align: left;
    }

    & > :last-child {
        text-align: right;
    }

    .title {
        opacity: 0.7;
        font-size: 11px;
    }
}

@container (min-width: 740px) {
    .active-ticker {
        padding: 0 40px;
    }
}

@container (max-width: 720px) {
    .active-ticker {
        padding: 0 10px;
    }
}

@container (min-width: 680px) {
    .ticker-symbol {
        height: 68px;
        width: 240px;

        .g-symbol-currency {
            width: 68px;
            height: 68px;
        }

        & > .name {
            font-size: 1.2rem;
        }

        & > .symbol {
            font-size: 1.6rem;
        }
    }

    .price {
        & > .value {
            font-size: 1.6rem;
        }

        & > .percent {
            font-size: 1.2rem;
        }
    }
}

@container (max-width: 680px) {
    .ticker-symbol {
        height: 60px;
        width: 186px;

        .g-symbol-currency {
            width: 42px;
            height: 42px;
        }

        & > .name {
            font-size: 1rem;
        }

        & > .symbol {
            font-size: 1.3rem;
        }
    }

    .price {
        & > .value {
            font-size: 1.3rem;
        }

        & > .percent {
            font-size: 1rem;
        }
    }

    .specifics {
        display: none !important;
    }
}
</style>