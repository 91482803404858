<script setup>

import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthActions } from '@auth';
import { i18n, PopUpManager as PopUp, STATE, verifyField } from '@/Ship';
import { useProfileActions } from '@profile';

const signIn = useAuthActions('signIn');
const getProfileResource = useProfileActions('getProfileResource');

const router = useRouter();
const profile = getProfileResource();

const rememberMe = ref(false);
const showPassword = ref(false);
const payload = reactive({
    email: '',
    password: '',
});

const errors = reactive({
    email: false,
    password: false,
});

const email = computed({
    get: () => payload.email,
    set: (value) => {
        payload.email = value;
        errors.email = verifyField(email.value, ['require', 'email']);

        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: (value) => {
        payload.password = value;
        errors.password = verifyField(password.value, ['require', 'minLength:6']);

        return true;
    }
});

if (localStorage.getItem('rememberMe')) {
    email.value = localStorage.getItem('email');
    password.value = localStorage.getItem('password');
    rememberMe.value = true;
}

const isAllow = computed(() => {
    for (const key in errors) {
        if (errors[key] !== null) {
            return false;
        }
    }

    return true;
});

const login = () => {
    if (!errors.email) {
        signIn(payload.email, payload.password).then(() => {
            if (rememberMe.value) {
                localStorage.setItem('email', payload.email);
                localStorage.setItem('password', payload.password);
                localStorage.setItem('rememberMe', true);
            } else {
                localStorage.removeItem('email');
                localStorage.removeItem('password');
                localStorage.removeItem('rememberMe');
            }

            return profile.load().then(() => {
                STATE.IS_LOGIN = true;
                router.push({ name: 'Trading', params: router.currentRoute.value.params });
            });
        }).catch(() => {
            PopUp.open(require('@auth/components/signUpInfoPopUp'))
                .setCaption(i18n.global.t('base.error', 'Error'))
                .setPayload({
                    payload:
                        {
                            mainText: i18n.global.t('base.something_wrong'),
                            additionText: i18n.global.t('auth.wrong_login_or_password')
                        }
                });
        });
    }
};

</script>

<template>
    <form class="sign-in" v-on:submit.prevent="login">
        <div class="overlay"></div>
        <g-caption size="2" align="center" weight="700">{{ $t('auth.sign_in') }}</g-caption>
        <g-field v-model="email"
                 type="email"
                 v-bind:label="$t('auth.email')"
                 v-bind:error="errors.email"/>
        <g-field v-model="password"
                 v-bind:type="showPassword ? 'text' : 'password'"
                 v-bind:label="$t('auth.password')"
                 v-bind:error="errors.password">
            <button type="button" class="button-input"
                    v-on:click="showPassword = !showPassword">
                <g-symbol :name="showPassword ? 'eye-open' : 'eye-close'" width="24" height="24"/>
            </button>
        </g-field>
        <g-checkbox v-model:checked="rememberMe">{{ $t('auth.remember', 'Remember Me') }}</g-checkbox>
        <router-link v-bind:to="{ name: 'ResetPassword', params: { locale: $i18n.locale } }" tag="a" class="link">
            {{ $t('auth.forgot_password') }}
        </router-link>

        <g-button type="submit" v-bind:disabled="!isAllow">{{ $t('auth.sign_in', 'Sign In') }}</g-button>

        <div class="center">
            {{ $t('auth.have_account') }}
            <router-link :to="{ name: 'SignUp', params: { locale: $i18n.locale } }" tag="a" class="link">
                {{ $t('auth.sign_up') }}
            </router-link>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.sign-in {
    overflow: hidden;
    height: 500px;
    max-width: 460px;
    z-index: 1;
    position: relative;
    padding: 40px 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: var(--env-bg, $env-bg);
    border: 1px solid var(--separator-color);
    border-radius: var(--bar-rounding, $bar-rounding);
    box-shadow: 0 0 30px -5px #4693b0aa;

    & > .overlay {
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--bar-bg, $bar-bg);
        clip-path: polygon(0 0, 30% 0, 70% 100%, 0 100%);
    }
}

.link {
    font-size: 106%;
    font-weight: 600;
    margin-left: 5px;
    color: var(--primary, $primary);
    transition: color 0.2s;

    &:hover {
        color: var(--primary-hover, $primary-hover);
    }
}

.center {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.g-button {
    margin: 25px 0;
    flex-basis: 100%;
    color: var(--title-text-color, $title-text-color);
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.button-input {
    display: flex;
    background-color: transparent;
}

.g-field {
    flex-basis: 100%;
    margin-bottom: 14px;
}

.g-caption {
    margin-bottom: 25px;
}
</style>