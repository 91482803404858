<script setup>

import { onMounted, onUnmounted, reactive, computed } from 'vue';
import { useShipActions, WebSockets as Stream, PopUpManager as PopUp, i18n } from '@/Ship';
import { usePositionActions } from '@position';
import { useQuoteActions } from '@quotes';
import { useProfileActions } from '@profile';

const {
    plCalculate,
    getPositionResource,
} = usePositionActions();

const {
    onlyNumberInput,
    definePriceScale
} = useShipActions();

const getProfileResource = useProfileActions('getProfileResource');
const getMarketResource = useQuoteActions('getMarketResource');

const resource = getPositionResource();
const profile = getProfileResource();
const market = getMarketResource();

const thresholds = reactive({
    takeProfits: {},
    stopLosses: {},
    stopLossLoaders: {},
    takeProfitLoaders: {},
});

const positions = computed(() => {
    return resource.state.map(position => {
        position.pair.isMarketClosed = market.isLoaded
            ? (market[position.pair.market.toLowerCase()].status  !== 'opened')
            : false;
        return position;
    }).sort((a, b) => b.created_at > a.created_at ? 1 : -1);
});

function setTakeProfit(position, isDrop = false) {
    thresholds.takeProfitLoaders[position.id] = true;
    resource.save(position.id, {
        take_profit: isDrop ? null : thresholds.takeProfits[position.id]
    }).then(() => {
        delete thresholds.takeProfits[position.id];
        setTimeout(() => {
            thresholds.takeProfitLoaders[position.id] = false;
        }, 3500);
    });
}

function setStopLoss(position, isDrop = false) {
    thresholds.stopLossLoaders[position.id] = true;
    resource.save(position.id, {
        stop_loss: isDrop ? null : thresholds.stopLosses[position.id]
    }).then(() => {
        delete thresholds.stopLosses[position.id];
        setTimeout(() => {
            thresholds.stopLossLoaders[position.id] = false;
        }, 3500);
    });
}

function marketPrice(position) {
    const price = Number(position.marketPrice);
    const multiplier = (parseInt(profile.multipliers[position.pair_symbol]) || 0) / 100;
    const pricescale = definePriceScale(price);

    return Math.floor((price + (price * multiplier)) * pricescale) / pricescale;
}

function closePosition(position) {
    PopUp.open(require('@position/components/ConfirmPopUp')).setCaption(i18n.global.t('trading.close_at_market')).setPayload({ payload: {id: position.id} });
}

/** Stream **/
const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

onMounted(() => {
    stream.subscribe({ trading: { user_id: profile.id, tab: 'positions' } });
    stream.addHandlers({
        positions: data => {
            resource.state = data;
            resource.state.forEach(plCalculate);
        },
    });
});

onUnmounted(() => {
    stream.unsubscribe('trading');
});
</script>

<template>
    <div class="positions">
        <div class="titles row">
            <span class="cell">{{ $t('trading.currency_pair') }}</span>
            <span class="cell">{{ $t('trading.type') }}</span>
            <span class="cell">{{ $t('trading.value') }}</span>
            <span class="cell amount">{{ $t('trading.quantity') }}</span>
            <span class="cell">{{ $t('trading.entry_price') }}</span>
            <span class="cell">{{ $t('trading.market_price') }}</span>
            <span class="cell">{{ $t('trading.pl') }}</span>
            <span class="cell">{{ $t('trading.take_profit') }}</span>
            <span class="cell">{{ $t('trading.stop_loss') }}</span>
            <span class="cell">{{ $t('trading.opened_at') }}</span>
            <span class="cell">{{ $t('trading.close_at_market') }}</span>
        </div>

        <div class="row"
             v-for="(position) in positions"
             v-bind:key="`position-${position.id}`"
             v-bind:class="{ locked: !!position.locked }">

            <span class="currency-pair cell">
                <g-flex gap="8" align="center">
                    <g-symbol-currency v-bind:name="position.pair.base.toLowerCase()" width="24" height="24"/>
                    <span><b>{{ position.pair.base }}</b>:{{ position.pair.counter }}</span>
                </g-flex>
            </span>
            <span class="type cell" v-bind:class="(position.direction || 'long').toLowerCase()" v-bind:data-field="$t('trading.type') + ': '">
                {{ position.direction }}
            </span>
            <span class="value cell" v-bind:data-field="$t('trading.value') + ': '">
                {{ Number(Number(position.value).toFixed(5)) }}
            </span>
            <span class="amount cell" v-bind:data-field="$t('trading.quantity') + ': '">
                {{ Number(position.amount) }}
                <b class="leverage" v-if="position.leverage > 1">✕{{ position.leverage }}</b>
            </span>
            <span class="entry-price cell" v-bind:data-field="$t('trading.entry_price') + ': '">
                <b>{{ Number(position.entry_price) }}</b> <small class="currency">{{ position.pair.counter }}</small>
            </span>
            <span class="market-price cell" v-bind:data-field="$t('trading.market_price') + ': '">
                <b>{{ marketPrice(position) }}</b> <small class="currency">{{ position.pair.counter }}</small>
            </span>
            <span class="pl cell"
                  v-bind:class="position.pl > 0 ? 'increase' : 'decrease'"
                  v-bind:data-field="$t('trading.pl') + ': '">
                {{ position.pl }} <small class="currency">USDT</small>
            </span>
            <span class="take-profit cell"
                  v-bind:class="{disabled: position.pair.isMarketClosed}"
                  v-bind:data-field="$t('trading.take_profit', 'Take profit') + ': '">
                <g-preloader-bar v-if="thresholds.takeProfitLoaders[position.id]"/>
                <g-flex v-else-if="position.take_profit" align="center" justify="start" gap="2">
                    at {{ position.take_profit.replace(/\.?0+$/, '') }} <small class="currency">{{ position.pair.counter }}</small>
                    <g-symbol name="close" v-on:click="setTakeProfit(position, true)" width="16" height="16"/>
                </g-flex>
                <template v-else>
                    <!--v-model="take[position.id]"-->
                    <g-input v-model="thresholds.takeProfits[position.id]"
                             type="number"
                             v-on:keypress="onlyNumberInput"
                             v-on:keypress.enter="setStopLoss(position)">
                        <button class="take-btn sell" v-on:click="setTakeProfit(position)">Ok</button>
                    </g-input>
                </template>
            </span>
            <span class="stop-loss cell"
                  v-bind:class="{disabled: position.pair.isMarketClosed}"
                  v-bind:data-field="$t('trading.stop_loss', 'Stop loss') + ': '">
                <g-preloader-bar v-if="thresholds.stopLossLoaders[position.id]"/>
                <g-flex v-else-if="position.stop_loss" align="center" justify="start" gap="2">
                    at {{ position.stop_loss.replace(/\.?0+$/, '') }} <small class="currency">{{ position.pair.counter }}</small>
                    <g-symbol name="close" v-on:click="setStopLoss(position, true)" width="16" height="16"/>
                </g-flex>
                <template v-else>
                    <g-input v-model="thresholds.stopLosses[position.id]"
                             v-on:keypress="onlyNumberInput"
                             v-on:keypress.enter="setStopLoss(position)"
                             type="number">
                        <g-button class="take-btn" v-on:click="setStopLoss(position)">Ok</g-button>
                    </g-input>
                </template>
            </span>

            <span class="cell opened-at" v-bind:data-field="$t('trading.opened_at', 'Opened at')">{{ position.created_at }}</span>

            <span class="action cell" v-bind:data-field="$t('trading.close_at_market') + ': '">
                <g-button class="close-btn"
                          v-bind:disabled="position.pair.isMarketClosed || !profile.can_close_position"
                          v-bind:class="position.pl > 0 ? 'increase' : 'decrease'"
                          v-on:click="closePosition(position)">
                    <g-symbol v-if="position.pair.isMarketClosed" name="lock" width="16" height="16"/>
                    <g-symbol v-else name="close" width="14" height="14"/>
                    {{ $t('base.close') }}
                </g-button>
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.positions {
    min-height: 360px;
    background-color: var(--bar-bg, $bar-bg);
}

.row {
    font-size: 0;
    white-space: nowrap;
    display: grid;
    align-items: center;
    padding: 6px 10px;
    color: var(--main-text-color, $main-text-color);
    //background-color: rgba($env-bg, 0.5);

    &:nth-child(odd) {
        background-color: var(--env-bg, $ticker-bg);
    }

    &.locked {
        opacity: 0.4;
        pointer-events: none;
        filter: grayscale(1);

        & > .cell:nth-child(6),
        & > .cell:nth-child(7) {
            filter: blur(2px);
        }
    }

    .cell {
        font-size: 13px;

        &.currency-pair {
            grid-area: crr;

            b {
                color: var(--title-text-color, $title-text-color);
            }
        }

        &.type {
            grid-area: typ;
        }

        &.value {
            grid-area: val;
        }

        &.amount {
            grid-area: amt;

            & > .leverage {
                color: var(--warning, $warning);
            }
        }

        &.entry-price {
            grid-area: prc;
        }

        &.market-price {
            grid-area: mrt;
        }

        &.pl {
            grid-area: prf;

            &::after {
                content: '⮕';
                width: 16px;
                height: 16px;
                font-size: 13px;
                line-height: 14px;
                text-align: center;
                display: inline-block;
                transition: transform 0.2s;
            }

            &.increase {
                color: var(--increase, $increase);

                &::after {
                    transform: rotate(-45deg);
                }
            }

            &.decrease {
                color: var(--decrease, $decrease);

                &::after {
                    transform: rotate(45deg);
                }
            }
        }

        &.take-profit {
            grid-area: tkp;
        }

        &.stop-loss {
            grid-area: stl;
        }

        &.stop-loss,
        &.take-profit {
            position: relative;

            &.disabled {
                opacity: 0.3;
                pointer-events: none;
                filter: grayscale(0.6);
            }

            & .g-symbol {
                cursor: pointer;
                fill: var(--main-text-color, $danger);

                &:hover {
                    fill: var(--title-text-color, $title-text-color);
                    transform: scale(1.2);
                }
            }

            & > .g-preloader-bar {
                min-height: 30px;
            }
        }

        &.opened-at {
            grid-area: opn;
        }

        &.action {
            grid-area: act;
        }

        &:last-child {
            text-align: center;
        }
    }

    .g-input {
        height: 32px;

        &:deep(input) {
            font-size: 12px;
        }
    }

    .take-btn {
        border-radius: 3px;
        cursor: pointer;
        margin-right: 4px;
        height: 22px;
        line-height: 20px;
        font-size: 13px;
        font-weight: bold;
        background-color: transparent;
        color: var(--primary-hover, $primary-hover);

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }
    }

    .currency {
        font-size: 86%;
    }

    .close-btn {
        width: 100%;
        height: 32px;
        font-size: 13px;
        color: var(--title-text-color, $title-text-color);
        background-color: transparent;

        &.increase {
            fill: var(--increase, $increase);

            &:hover {
                border: 1px solid var(--increase, $increase);
            }
        }

        &.decrease {
            fill: var(--decrease, $decrease);

            &:hover {
                border: 1px solid var(--decrease, $decrease);
            }
        }
    }
}

.titles {
    border-bottom: 1px solid var(--separator-color, $separator-color);
    margin-bottom: 0;
    font-family: $font-title;
}

@media (min-width: 1885px) {
    .row {
        grid-gap: 6px;
        grid-template-rows: repeat(auto-fill, 32px);
        grid-template-areas: 'crr typ amt val prc mrt prf tkp stl opn act';
        grid-template-columns: 110px 45px 72px repeat(4, minmax(min-content, 1fr)) 120px 120px 120px 96px;
    }
}

@media (max-width: 1884px) {
    .titles {
        display: none;
    }

    .row {
        // box-shadow: 0 10px 12px -12px var(--field-bg, $field-bg);
        grid-template-rows: repeat(auto-fill, 1fr);

        & > .cell {
            padding: 8px 4px;

            &::before {
                display: block;
                font-size: 12px;
                content: attr(data-field);
                color: var(--label-color, $label-color);
            }
        }

        .currency-pair {
            font-size: 16px !important;

            .g-symbol-currency {
                width: 32px;
                height: 32px;
            }
        }
    }
}

@media (min-width: 1585px) AND (max-width: 1884px) {
    .row {
        grid-template-areas:
            'crr crr crr crr typ tkp opn'
            'val amt prc mrt prf stl act';
        grid-template-columns: repeat(5, minmax(min-content, 1fr)) 120px 140px;

        & > .cell {
            font-size: 13px;
        }
    }
}

@media (min-width: 1285px) AND (max-width: 1584px) {
    .row {
        grid-template-areas:
            'crr crr prc typ tkp opn'
            'val amt mrt prf stl act';
        grid-template-columns: repeat(4, minmax(min-content, 1fr)) 120px 140px;

        & > .cell {
            font-size: 13px;
        }
    }
}

@media (min-width: 485px) AND (max-width: 1284px) {
    .row {
        grid-template-areas:
            'crr crr typ opn'
            'prc val tkp prf'
            'mrt amt stl act';
        grid-template-columns: repeat(3, minmax(min-content, 1fr)) 140px;

        & > .cell {
            font-size: 13px;
        }
    }
}

@media (max-width: 484px) {
    .row {
        grid-template-areas:
            'crr crr opn'
            'prf typ tkp'
            'mrt amt stl'
            'prc val act';
        grid-template-columns: auto auto 120px;
    }
}
</style>
