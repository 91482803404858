<template>
    <div class="profile environment">
            <infobar/>
            <profile-form/>
            <documents class="disabled"/>
    </div>
</template>

<script>

import { STATE } from '@/Ship';
import { useProfileActions, useProfileComponents } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

const {
    ProfileForm,
    Infobar,
    Documents,
} = useProfileComponents();

export default {
    name: 'Environment',
    components: {
        ProfileForm,
        Infobar,
        Documents
    },
    setup: function () {
        const profile = getProfileResource();

        let timeout = null;
        const logChanges = () => {

            if (profile.isValidationPassed()) {
                STATE.IS_PROCESSING = true;
                STATE.AUTO_SAVE = false;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    STATE.IS_PROCESSING = false;
                    STATE.AUTO_SAVE = true;
                    profile.save();
                    profile.clearEntity();
                }, 5000);
            }
        };

        return {
            profile,
            logChanges,
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    display: grid;
}

.infobar {
    grid-area: bar;
    background-color: var(--env-bg, $env-bg);
}

.profile-form {
    grid-area: frm;
    background-color: var(--env-bg, $env-bg);
}

.documents {
    grid-area: doc;
}

@media (min-width: 1025px) {
    .environment {
        padding: 4vw;
        grid-gap: 4vw;
        grid-template-areas: 'bar frm doc';
        grid-template-columns: 280px minmax(min-content, 400px) auto;
    }
}

@media (min-width: 625px) AND (max-width: 1024px) {
    .environment {
        padding: 4vw;
        grid-gap: 4vw;
        grid-template-areas:
            'bar frm'
            'bar doc';
        grid-template-columns: minmax(min-content, 300px) auto;
    }
}

@media (max-width: 624px) {
    .environment {
        padding: 20px;
        grid-gap: 4vw;
        grid-template-areas:
            'bar'
            'frm'
            'doc';
        grid-template-columns: 1fr;
    }
}

</style>
