<script setup>

import { computed, ref, toRefs, defineProps } from 'vue';
import { useOrderBookActions } from '@order-book';
import { useChartActions } from '@chart';

const props = defineProps(['base', 'counter']);

const {
    getOrderResource
} = useOrderBookActions();
const orders = getOrderResource();

const getBarResource = useChartActions('getBarResource');
const bar = getBarResource();

const { base, counter } = toRefs(props);

const container = ref();
const increased = ref();

const marketPrice = computed(() => {
    increased.value = bar.model.close < marketPrice.value
        ? 'increase'
        : 'decrease';

    return bar.model.close;
});

function fillAmount(value) {
    return Math.floor(value * 100 / 3500) + 36;
}

bar.onload.push(() => {
    setTimeout(() => {
        if (!!container.value) {
            container.value.scrollTo(0, (container.value.scrollHeight - container.value.offsetHeight) / 2);
        }
    }, 100);
});

</script>

<template>
    <div class="orders" ref="container">
        <g-flex class="row titles" justify="between">
            <div class="total cell">{{ $t('trading.total', 'Total') }}</div>
            <div class="amount cell">{{ $t('trading.amount', 'Amount') }}</div>
            <div class="price cell">{{ $t('trading.price', 'Price') }}</div>
        </g-flex>

        <g-flex class="buy" justify="between">
            <div class="amount-container">
                <g-flex class="row" v-bind:class="[{drop: order.drop}, {affected: order.affected}]" v-for="(order, i) in orders.buys" v-bind:key="`order-buy-${i}`" justify="between" gap="50">
                    <div class="total cell">{{ order.total }}</div>
                    <div class="amount cell">{{ order.amount }}</div>
                </g-flex>
            </div>
            <div class="price-container">
                <div class="price cell" v-for="(order, i) in orders.buys" v-bind:key="`order-buy-price-${i}`">
                    <div class="volume" v-bind:style="`width: ${ fillAmount(order.amount) }%`"></div>
                    {{ order.price }}
                </div>
            </div>
        </g-flex>

        <g-flex class="market-price" v-bind:class="increased" align="center" justify="between" gap="5">
            <g-symbol-currency v-bind:name="counter.toLowerCase()" width="22" height="22"/>
            {{ marketPrice }}
        </g-flex>

        <g-flex class="sell" justify="between">
            <div class="amount-container">
                <g-flex class="row" v-bind:class="[{drop: order.drop}, {affected: order.affected}]" v-for="(order, i) in orders.sells" v-bind:key="`order-sell-${i}`" justify="between" gap="50">
                    <div class="total cell">{{ order.total }}</div>
                    <div class="amount cell">{{ order.amount }}</div>
                </g-flex>
            </div>
            <div class="price-container">
                <div class="price cell" v-for="(order, i) in orders.sells" v-bind:key="`order-sell-price-${i}`">
                    <div class="volume" v-bind:style="`width: ${ fillAmount(order.amount) }%`"></div>
                    {{ order.price }}
                </div>
            </div>
        </g-flex>
    </div>
</template>

<style lang="scss" scoped>
.orders {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--env-bg, $env-bg);
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.titles {
    top: 0;
    z-index: 2;
    position: sticky;
    padding: 4px 10px;
    background-color: var(--bar-bg, $bar-bg);
    border-bottom: 1px solid var(--separator-color, $separator-color);

    & > .total {
        flex-grow: 1;
    }

    & > .price {
        width: 70%;
        text-align: right;
        flex-shrink: 0;
    }
}

.buy {
    border-bottom: 1px solid var(--separator-color, $separator-color);

    .volume {
        background-color: var(--increase, $increase);
    }
}

.sell {
    border-top: 1px solid var(--separator-color, $separator-color);

    .volume {
        background-color: var(--decrease, $decrease);
    }
}

.row {
    &.drop {
        color: var(--main-text-color, $main-text-color);
    }

    &.affected {
        & > .total,
        & > .amount {
            animation: affected 0.5s;
        }
    }
}

.amount-container {
    //background-color: var(--bar-bg, $bar-bg);
    border-right: 1px solid var(--separator-color, $separator-color);

    & > .row {
        padding: 0 10px;
        //background-color: var(--bar-bg, $bar-bg);
        background-color: var(--env-bg, $env-bg);

        &:not(:last-child) {
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }
        //&:nth-child(even) {
        //    background-color: var(--bar-bg, $bar-bg);
        //}
        //
        //&:nth-child(odd) {
        //    background-color: var(--env-bg, $env-bg);
        //}
    }
}

.price-container {
    flex-grow: 1;
    width: 70%;
    //background-color: var(--env-bg, $env-bg);
    background-color: var(--bar-bg, $bar-bg);

    & > .price {
        z-index: 1;
        position: relative;
        padding-right: 8px;
        text-align: right;
        color: var(--title-text-color, $title-text-color);

        &:not(:last-child) {
            border-bottom: 1px solid transparent;
        }
    }

    .volume {
        top: 1px;
        right: 0;
        z-index: -1;
        position: absolute;
        max-width: 100%;
        min-width: 68px;
        height: calc(100% - 2px);
        transition: width 0.3s;
        opacity: 0.4;
    }
}

.cell {
    padding: 3px 0;
    font-size: 13px;
}

.market-price {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 30px 8px 10px;
    position: relative;
    background-color: var(--bar-bg, $bar-bg);

    &::after {
        position: absolute;
        right: 10px;
        content: '⮕';
        width: 15px;
        height: 15px;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        transition: transform 0.2s;
    }

    &.increase {
        color: var(--increase, $increase);

        &::after {
            transform: rotate(-45deg);
        }
    }

    &.decrease {
        color: var(--decrease, $decrease);

        &::after {
            transform: rotate(45deg);
        }
    }
}

@keyframes affected {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

</style>
