<template>
    <div class="order-book">

        <g-flex class="expand-btn" v-bind:class="{collapsed: !expand}" v-on:click="expand = !expand" justify="between" align="center" gap="10">
            {{ $t('lead.order_book', 'Order Book') }}
            <hr class="separator"/>
            <g-symbol name="arrow-down" width="24" height="24"/>
        </g-flex>

        <div class="container" v-bind:class="{expand: expand}">
            <g-tabs v-model="tab" v-bind:tab-list="tabList"/>

            <g-flex v-if="market.status !== 'opened'" class="lock" direction="column" align="center" justify="center">
                <g-symbol name="lock" width="36" height="36"/>
                <g-caption size="4">The market is currently closed</g-caption>
                <g-caption v-if="!!market.reason" size="5">{{ market.reason }}</g-caption>
                <g-caption v-if="!!market.openingTimer" size="5">Opening in {{ market.openingTimer }}</g-caption>
            </g-flex>
            <component v-else
                       v-bind:is="tab"
                       v-bind:base="base"
                       v-bind:counter="counter"/>
        </div>
    </div>
</template>

<script>

import { watch, ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { i18n, useShipActions, WebSockets as Connection } from '@/Ship';
import { useOrderBookComponents, useOrderBookActions } from '@order-book';
import { useChartActions } from '@chart';
import { useQuoteActions } from '@quotes';

const getBarResource = useChartActions('getBarResource');
const {
    getMarketResource,
    getCryptoResource,
    getStockResource,
    getCommodityResource,
    getForexResource,
} = useQuoteActions();

const {
    Orders,
    Trades
} = useOrderBookComponents();

const {
    getOrderResource,
    getTradeResource,

    fillTrades,
    makeOrder,
    fillOrders,
} = useOrderBookActions();

const probability = useShipActions('probability');

export default {
    name: 'View',

    components: {
        Orders,
        Trades,
    },

    setup() {

        const tab = ref('orders');
        const bar = getBarResource();
        const route = useRoute();

        let precision = 2;
        let interval = null;
        const maxAmount  = 5000;
        let dropping = false;

        const tabList = reactive({
            orders: i18n.global.t(`parts.orders`, 'Orders'),
            trades: i18n.global.t(`parts.trades`, 'Trades'),
        });

        const market = getMarketResource();

        const base = computed(() => {
            return (route.params.base || 'BTC').toUpperCase();
        });

        const counter = computed(() => {
            return (route.params.counter || 'USDT').toUpperCase();
        });

        const trades = getTradeResource();
        const orders = getOrderResource();
        const previousPrice = ref(0);

        const connection = Connection.getInstance(process.env.VUE_APP_WEBSOCKET);

        const expand = ref(false);

        function sliceBuyOrders(price) {
            for (let i = orders.buys.length; i--;) {
                if (orders.buys[i].price < price) {
                    orders.buys[i].drop = false;
                    break;
                }
                orders.buys[i].drop = true;
            }
            dropping = true;
            setTimeout(() => {
                while(orders.topBuy.drop) {
                    orders.buys.unshift(
                        fillOrders(orders.buys[0].price, 'buy', 1).pop()
                    );
                    buyDeal();
                }
                dropping = false;
            }, 600);
        }
        function sliceSellOrders(price) {
            for (let i = 0; i < orders.sells.length; i++) {
                if (orders.sells[i].price > price) {
                    orders.sells[i].drop = false;
                    break;
                }
                orders.sells[i].drop = true;
            }
            dropping = true;
            setTimeout(() => {
                while(orders.topSell.drop) {
                    orders.sells.push(
                        fillOrders(orders.sells[orders.sells.length - 1].price, 'sell', 1).pop()
                    );
                    sellDeal();
                }
                dropping = false;
            }, 600);
        }

        function buyDeal(amount) {
            amount = amount || orders.topBuy.amount;
            if (amount < orders.topBuy.amount) {
                orders.topBuy.drop = true;
                orders.topBuy.affected = !orders.topBuy.affected;
                orders.topBuy.amount -= amount;
                const total = amount / orders.topBuy.price;
                trades.state.unshift({
                    amount,
                    price: orders.topBuy.price,
                    total: precision > 3
                        ? Math.floor(total)
                        : Number(total.toFixed(5)),
                    type: 'buy',
                });
            } else {
                trades.state.unshift({
                    type: 'buy',
                    ...orders.buys.pop()
                });
            }
            trades.state.pop();
        }

        function sellDeal(amount) {
            amount = amount || orders.topSell.amount;
            if (amount < orders.topSell.amount) {
                orders.topSell.drop = true;
                orders.topSell.affected = !orders.topSell.affected;
                orders.topSell.amount -= amount;
                const total = amount / orders.topSell.price;
                trades.state.unshift({
                    amount,
                    price: orders.topSell.price,
                    total: precision > 3
                        ? Math.floor(total)
                        : Number(total.toFixed(5)),
                    type: 'sell',
                });
            } else {
                trades.state.unshift({
                    type: 'sell',
                    ...orders.sells.shift()
                });
            }
            trades.state.pop();
        }

        function refreshOrders() {
            if (dropping || bar.isLoading || !bar.isLoaded || !connection.isReady) {
                return;
            }

            const sellDelta = orders.topSell.price - bar.model.close;
            const buyDelta = bar.model.close - orders.topBuy.price;

            if (probability(sellDelta > buyDelta ? 80 : 20)) {
                const order = makeOrder(bar.model.close, orders.topSell.price);
                if (order.price === orders.topSell.price) {

                    const amount = Math.floor(Math.random() * (5 - 1) + 1) * 100;

                    if (probability(orders.topSell.amount < (maxAmount / 2) ? 80 : 20)) {
                        orders.topSell.affected = !orders.topSell.affected;
                        orders.topSell.amount += amount;
                        orders.topSell.drop = false;
                        const total = orders.topSell.amount / orders.topSell.price;
                        orders.topSell.total = precision > 3
                            ? Math.floor(total)
                            : Number(total.toFixed(5));
                    } else {
                        sellDeal(amount);
                    }
                } else {
                    if (orders.sells.length >= orders.buys.length) {
                        orders.sells.pop();
                    }
                    orders.topSell.drop = false;
                    orders.sells.unshift(order);
                }

            } else {
                const order = makeOrder(orders.topBuy.price, bar.model.close);
                if (order.price === orders.topBuy.price) {

                    const amount = Math.floor(Math.random() * (5 - 1) + 1) * 100;

                    if (probability(orders.topBuy.amount < (maxAmount / 2) ? 80 : 20)) {
                        orders.topBuy.amount += amount;
                        orders.topBuy.drop = false;
                        orders.topBuy.affected = !orders.topBuy.affected;
                        const total = orders.topBuy.amount / orders.topBuy.price;
                        orders.topBuy.total = precision > 3
                            ? Math.floor(total)
                            : Number(total.toFixed(5));
                    } else {
                        buyDeal(amount);
                    }
                } else {
                    if (orders.buys.length >= orders.sells.length) {
                        orders.buys.shift();
                    }
                    orders.topBuy.drop = false;
                    orders.buys.push(order);
                }
            }
        }

        bar.onload.push(() => {

            const resource = {
                crypto: getCryptoResource,
                stocks: getStockResource,
                commodities: getCommodityResource,
                forex: getForexResource,
            }[market.name]();

            precision = Number(bar.model.close).toString().split('.')[1]?.length || 2;

            trades.state = fillTrades(bar.model.close, 40);
            orders.buys = fillOrders(bar.model.close, 'buy', 20);
            orders.sells = fillOrders(bar.model.close, 'sell', 20);

            previousPrice.value = bar.model.close;

            if (market.status === 'opened') {
                clearInterval(interval);
                interval = setInterval(refreshOrders, (resource.model.volatility || 20) * 1000);
            }
        });

        watch(() => market.status, () => {
            if (market.status !== 'opened') {
                clearInterval(interval);
            }
        });

        watch(() => bar.model.close, () => {
            if (market.status === 'opened') {
                (bar.model.close < previousPrice.value)
                    ? sliceBuyOrders(Number(bar.model.close))
                    : sliceSellOrders(Number(bar.model.close));
            }

            previousPrice.value = bar.model.close;
        });


        return {
            expand,
            tab,
            tabList,
            base,
            counter,
            market,
        };
    }
};
</script>

<style lang="scss" scoped>
.order-book {
    position: relative;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
        &:last-child {
            flex-grow: 1;
        }
    }
}

.tabbar {
    width: 100%;
    display: flex;
    font-size: 16px;
    height: var(--bar-height, $bar-height);

    & > .tab-btn {
        width: 50%;

        &:not(.active-tab) {
            color: var(--main-text-color, $main-text-color);
        }

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }
    }
}

.lock {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #131722dd;
    fill: var(--main-text-color, $main-text-color);

    & > :last-child {
        color: var(--success-hover, $success-hover);
    }
}

.expand-btn {
    cursor: pointer;
    padding: 10px;
    fill: var(--main-text-color, $main-text-color);

    &:not(.collapsed) > .g-symbol {
        transform: rotate(180deg);
    }
}

.separator {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: var(--main-text-color, $main-text-color);
}

@media (min-width: 725px) {
    .expand-btn {
        display: none !important;
    }
}

@media (max-width: 724px) {
    .container {
        overflow: hidden;
        max-height: 480px;

        &:not(.expand) {
            display: none;
        }
    }
}
</style>
