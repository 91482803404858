import { ApiClient, STACK, useShipResources} from '@/Ship';

const { BaseResource } = useShipResources();

export default class Position extends BaseResource {

    static #instance = null;

    endpoint = 'positions';

    section = 'Trading';
    container = 'Position';

    constructor() {
        super();
        if (!Position.#instance) {
            Position.#instance = this;
        }

        return Position.#instance;
    }

    static getInstance() {
        return new Position();
    }

    find(id) {
        return this.collection.value.find(position => position.id === id);
    }

    create(payload) {
        return STACK.push(() => {
            return ApiClient.post(this.endpoint, payload);
        });
    }
}
