<template>
    <div class="wrapper">
        <topbar/>
        <environment/>
    </div>
</template>

<script>

import { useEnvComponents } from '@overlay';

const Topbar = useEnvComponents('Topbar');

export default {
    name: 'Layout',
    components: {
        Topbar,
    }
};
</script>

<style lang="scss">
.topbar {
    width: 100%;
    height: var(--topbar-height, $topbar-height);
}
</style>
