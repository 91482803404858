<script setup>

import { useRouter } from 'vue-router';
import { computed, ref, reactive } from 'vue';
import { useAuthComponents, useAuthActions } from '@auth';
import { i18n, PopUpManager as PopUp, verifyField } from '@/Ship';
import { useProfileActions } from '@profile';

const { signUp, signIn } = useAuthActions();
const getCountryResource = useProfileActions('getCountryResource');


const PasswordInput = useAuthComponents('PasswordInput')

const countries = getCountryResource();
const router = useRouter();


const isComplex = ref(false);

const payload = reactive({
    first_name: '',
    last_name: '',
    phone: '',
    country_code: '',
    email: '',
    password: '',
    domain: location.host,
});

const errors = reactive({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    country_code: false,
    password: false,
});

const firstName = computed({
    get: () => payload.first_name,
    set: value => {
        payload.first_name = value;
        errors.first_name = verifyField(value, ['require', 'minLength:2']);

        return true;
    }
});

const lastName = computed({
    get: () => payload.last_name,
    set: value => {
        payload.last_name = value;
        errors.last_name = verifyField(value, ['require', 'minLength:2']);

        return true;
    }
});

const email = computed({
    get: () => payload.email,
    set: value => {
        payload.email = value;
        errors.email = verifyField(value, ['require', 'email']);

        return true;
    }
});

const countryCode = computed({
    get: () => payload.country_code,
    set: value => {
        payload.country_code = value;
        errors.country_code = verifyField(value, ['require']);

        return true;
    }
});

const phone = computed({
    get: () => payload.phone,
    set: value => {
        payload.phone = value;
        errors.phone = verifyField(value, ['require', 'phone']);

        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: value => {
        payload.password = value;
        errors.password = isComplex.value
            ? null
            : i18n.global.t('validator.password');

        return true;
    }
});

const isAllow = computed(() => {
    for (const key in errors) {
        if (errors[key] !== null) {
            return false;
        }
    }

    return true;
});


function registration() {
    if (isAllow.value) {
        signUp(payload).then(() => {
            signIn(payload.email, payload.password).then(() => {
                router.push({ name: 'Trading', params: router.currentRoute.value.params });
            }).catch(() => {
                PopUp.open(require('@auth/components/signUpInfoPopUp'))
                    .setCaption(i18n.global.t('base.info'))
                    .setPayload({
                        payload: {
                            mainText: i18n.global.t('base.something_wrong'),
                            additionText: i18n.global.t('auth.user_already_registered')
                        }
                    });
            });
        });
    }
}
</script>

<template>
    <form class="sign-up" v-on:submit.prevent="registration">
        <div class="overlay"></div>
        <div>
            <g-caption size="2" weight="700">{{ $t('auth.sign_up', 'Sign Up') }}</g-caption>
            <div class="subtitle">{{ $t('auth.sign_sub_title') }}</div>
        </div>

        <div>
            <g-field v-model="firstName"
                     v-bind:label="$t('profile.first_name', 'First Name')"
                     v-bind:error="errors.first_name"/>
            <g-field v-model="lastName"
                     v-bind:label="$t('profile.last_name', 'Last Name')"
                     v-bind:error="errors.last_name"/>

            <g-phone-input v-model="phone"
                           v-model:country="countryCode"
                           v-bind:label="$t('profile.phone', 'Phone')"
                           v-bind:countries="countries.state"
                           v-bind:error="errors.phone"/>

            <g-field type="email"
                     v-model="email"
                     v-bind:label="$t('profile.email', 'Email')"
                     v-bind::error="errors.email"/>

            <hr class="separator">

            <div class="subtitle">{{ $t('auth.reset_sub_title_password') }}</div>


            <password-input v-model="password"
                            v-model:complex="isComplex"
                            v-bind:label="$t('auth.password')"
                            v-bind:error="errors.password"/>
        </div>

        <g-button class="send-btn"
                  v-bind:disabled="!isAllow">
            {{ $t('auth.create_account', 'Create Account') }}
        </g-button>

        <div>
            {{ $t('auth.sign_have_account') }}
            <router-link v-bind:to="{ name: 'SignIn', params: { locale: $i18n.locale } }" tag="a" className="link">
                {{ $t('auth.sign_in', 'Sign In') }}
            </router-link>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.sign-up {
    overflow: hidden;
    z-index: 1;
    position: relative;
    padding: 40px 30px;
    max-width: 460px;
    box-sizing: border-box;
    background-color: var(--env-bg, $env-bg);
    border: 1px solid var(--separator-color);
    border-radius: var(--bar-rounding, $bar-rounding);
    box-shadow: 0 0 30px -5px #4693b0aa;

    & > .overlay {
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--bar-bg, $bar-bg);
        clip-path: polygon(0 0, 30% 0, 70% 100%, 0 100%);
    }
}

.g-checkbox {
    vertical-align: top;
}

.send-btn {
    width: 100%;
    margin: 20px 0;
    color: var(--title-text-color, $title-text-color);
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.g-field,
.g-input,
.password-input {
    margin: 8px 0 10px;
    padding-top: 1px;
}

.g-phone-input {
    margin-top: 18px;
}

.link {
    margin: 0 5px;
    font-size: 106%;
    font-weight: 600;
    white-space: nowrap;
    color: var(--primary, $primary);
    transition: color 0.2s;

    &:hover {
        color: var(--primary-hover, $primary-hover);
    }
}

.subtitle {
    color: var(--title-text-color, $title-text-color);
}

.separator {
    height: 1px;
    border: none;
    margin-top: 20px;
    background-color: var(--separator-color, $separator-color);
}
</style>
