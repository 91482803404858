<script setup>

import { ref, defineProps, defineEmits } from 'vue';


const props = defineProps({
    modelValue: {
        type: String,
    },
    filled: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['update:modelValue', 'update:filled']);

const codeLength = 5;
const pass = ref('pass'); // bug in Chrome browser - is that is why this code is need
const code = ref(Array(codeLength));

function nextInput(inp) {
    const nxt = inp.nextElementSibling;
    if (nxt && nxt.nodeName === 'INPUT') {
        nxt.focus();
    }
}

function previousInput(inp) {
    const prev = inp.previousElementSibling;
    if (prev && prev.nodeName === 'INPUT') {
        prev.focus();
    }
}

function updateModel() {
    const value = code.value.join('');
    emit('update:modelValue', value);
    emit('update:filled', value.length === codeLength);
}

function keydownHandler(event) {
    if (/\d/.test(event.key)) {
        event.target.value = null;
        event.target.onkeyup = () => {
            event.target.onkeyup = null;
            nextInput(event.target);
            updateModel();
        };
    } else if (event.keyCode === 37) {
        previousInput(event.target);
    } else if (event.keyCode === 39) {
        nextInput(event.target);
    } else if (event.keyCode === 8) {
        event.target.onkeyup = () => {
            event.target.onkeyup = null;
            previousInput(event.target);
        };
    } else if (!event.ctrlKey && /\w/.test(event.key)) {
        event.preventDefault();
    }
}

function onPaste(event) {
    const data = event.clipboardData
        .getData('text')
        .trim()
        .split('')
        .filter(itm => /\d/.test(itm));

    for (let i = codeLength; i--;) {
        code.value[i] = data[i] || null;
    }
}
</script>

<template>
    <div class="verification-code">
        <input v-for="(itm, i) in code" v-bind:key="i"
               v-model="code[i]"
               type='text'
               ref='pass'
               v-on:keydown="keydownHandler"
               v-on:paste.prevent="onPaste"
               readonly
               @focus="pass[i].removeAttribute('readonly')"
               required/>
    </div>
</template>

<style lang="scss" scoped>
.verification-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    input {
        position: relative;
        padding: 0;
        border: 2px solid var(--input-border, $input-border);
        transition: border-color 0.2s;
        color: var(--title-text-color, $title-text-color);
        background-color: var(--input-bg, $input-bg);
        border-radius: var(--input-rounding, $input-rounding);
        display: inline-flex;
        width: 60px;
        height: 60px;
        text-align: center;
        font-size: var(--text-xl, $text-xl);
        font-weight: 400;
        font-family: var(--font-primary, $font-primary);
        box-sizing: border-box;

        &:focus {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--success, $success);
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
</style>